/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Image from 'gatsby-image';
import Box from '@fify.at/gatsby-theme-base/src/components/Box/Box';
import Flex from '@fify.at/gatsby-theme-base/src/components/Flex/Flex';
import Ratings from './Raitings/Raitings';

export default function TestimonialsSection({ testimonials, ...rest }) {
  return (
    <Section sx={{ paddingY: 8 }} background="muted">
      <Heading
        sx={{ fontWeight: 'heading', textAlign: 'center', color: 'primary' }}
      >
        Zufriedene Kunden
      </Heading>
      <p sx={{ fontStyle: 'italic', marginBottom: 5, textAlign: 'center' }}>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      </p>
      {testimonials && (
        <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
          {testimonials.map(testimonial => (
            <Testimonial
              key={testimonial.name}
              {...testimonial}
              sx={{
                maxWidth: ['100%', '100%', '50%', '25%'],
                boxSizing: 'border-box',
                paddingX: 3,
                textAlign: 'center',
              }}
            />
          ))}
        </Flex>
      )}
    </Section>
  );
}

function Testimonial({ image, text, name, position, rating, date, className }) {
  return (
    <Box className={className}>
      {image && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'primary',
            marginX: 'auto',
            display: 'inline-flex',
            borderRadius: 999,
            padding: 12,
            marginBottom: 5,
          }}
        >
          <Image
            {...image}
            sx={{
              width: '100%',
              borderRadius: 999,
              border: '8px solid #fff',
              boxSizing: 'content-box',
            }}
          />
        </Box>
      )}
      <h4 sx={{ marginBottom: 1, fontWeight: 'bold' }}>{name}</h4>
      <p sx={{ fontStyle: 'italic', marginBottom: 5 }}>{position}</p>
      <p sx={{ fontStyle: 'italic', marginBottom: 5 }}>{`${text.slice(
        0,
        200
      )} ...`}</p>
      <Ratings rating={rating} />
      <p>{date}</p>
    </Box>
  );
}
