/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';
import Text_Image from '../../components/raw_templates/Text_Image';
import ComparsionSection from '../../components/CompartionSection';
import TestimonialsSection from '../../components/TestimonialsSection';
import Ankaufsprozess from '../../components/ReusableSections/Ankaufsprozess';

export default function Leistungen({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      description="Individuelle Beratung wird bei WirkaufendeinHaus.at groß geschrieben.<br/>Bei uns ist Ihr Anliegen rund um das Thema Immobilie in den besten Händen."
      image={data.hero.childImageSharp.fluid}
      title="Leistungen"
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={true}
        orientation="southEast"
        variant="alpha"
        title="Unsere Leistungen im Überblick"
        image={data.hero}
      />

      <Text_Image
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.hero}
        title="INDIVIDUELLE BERATUNG – VON MENSCHEN FÜR MENSCHEN"
        text="<p>Individuelle Beratung wird bei WirkaufendeinHaus.at groß geschrieben.<br/>Bei uns ist Ihr
Anliegen rund um das Thema Immobilie in den besten Händen.</p>"
        buttonText='Entdecken Sie unser Leistungspaket <i class="fal fa-arrow-right"></i>'
        buttonLink="/leistungen/besichtigung-und-individuelle-beratung/"
      />

      <Ankaufsprozess />

      <Text_calltoaction
        sectionBackground="white"
        title="DIE RECHTLICHE ABWICKLUNG BEIM ANKAUF IHRES HAUSES"
        text="<p>Lehnen Sie sich zurück! WirkaufendeinHaus.at übernimmt das für Sie.</p>"
        buttons={[
          {
            text: 'Mehr erfahren <i class="fal fa-arrow-right"></i>',
            to: '/leistungen/rechtliche-abwicklung/',
          },
        ]}
      />

      <Text_Image
        textLeft
        background="muted"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.hero}
        title="WERTINDIKATION & BEWERTUNGSGUTACHTEN – SO VIEL IST MEIN HAUS WIRKLICH WERT!"
        text="<p>Die richtige Einschätzung Ihres Immobilienwerts gibt Ihnen Sicherheit beim Verkauf Ihres
Hauses.</p>"
        buttonText='Mehr erfahren <i class="fal fa-arrow-right"></i>'
        buttonLink="/leistungen/wertindikation-bewertungsgutachten/"
      />

      <Text_calltoaction
        sectionBackground="white"
        title="SIE BRAUCHE HILFE BEIM UMZUG? WIR NEHMEN IHNEN DIE ARBEIT AB!"
        text="<p>Mit unserem maßgeschneiderten Übersiedelungsservice finden wir garantiert die passende
Lösung für Ihren Umzug.</p>"
        buttons={[
          {
            text: 'Jetzt Angebot einholen <i class="fal fa-arrow-right"></i>',
            to: '/leistungen/uebersiedelungsservice/',
          },
        ]}
      />

      <ComparsionSection
        background="muted"
        title="Warum WirKaufenDeinHaus.at einfach besser ist"
        header={[
          {
            pro: 'WirKaufenDeinHaus.at',
            con: 'Traditioneller, privater Hausverkauf',
          },
        ]}
        text={[
          {
            pro:
              'Schnelle und einfache Abwicklung –  direkt vom Sofa aus, ganz ohne Aufwand und Ärger!',
            con: 'Aufwendige, eigenhändige Eruierung des Marktwerts',
          },
          {
            pro:
              'Kostenlose Online Immobilienbewertung auf nur einen Klick zur ersten Einschätzung des Marktwerts',
            con: 'Der gesamte Abwicklungsprozess liegt in Ihren Händen',
          },
          {
            pro:
              'Persönliche Objektanalyse vor Ort durch einen regionalen Experten',
            con:
              'Risiko eines Ausfalls der Käuferfinanzierung: die Bonität des Käufers muss selbst beurteilt werden',
          },
          {
            pro: 'Rechtsverbindliche Angebotslegung in nur 48 Stunden',
            con: 'Hoher Aufwand für Sanierungsmaßnahmen',
          },
          {
            pro: 'Keine Maklerprovisionen und Zusatzkosten',
            con:
              'Fehlendes Netzwerk: bei Detailfragen bleibt Ihnen der Weg zu einem Experten nicht erspart',
          },
          {
            pro: 'Kein Finanzierungsausfallrisiko',
            con:
              'Zeitaufwand für Besichtigungen und Risiko von Immobilientouristen',
          },
          {
            pro:
              'Keine zusätzlichen Aufwände oder Sanierungsmaßnahmen erforderlich',
            con:
              'Eigenständige Vorbereitung sämtlicher Formalitäten und Unterlagen',
          },
          {
            pro:
              'Übernahme sämtlicher Formalitäten, Verträge und Behördengänge',
            con: 'Eigene Abwicklung der Behördenwege und Vertragserstellung',
          },
          {
            pro:
              'Vertragserstellung und Treuhandabwicklung durch einen Notar oder Rechtsanwalt',
            con: '',
          },
          {
            pro: 'Optional: Übersiedlungsservice',
            con: '',
          },
        ]}
      />

      <TestimonialsSection
        testimonials={[
          {
            image: data.person_1.childImageSharp,
            text:
              'Nach meiner Scheidung habe ich versucht, mein Elternhaus zu erhalten. Ich rutschte immer mehr in Schulden hinein. Die Makler schwärmten von hohen Preisen und letztendlich nahm ich den Makler mit der höchsten Preiseinschätzung. Ein paar Monate später stand ich kurz vorm Konkurs! WirkaufendeinHaus.at hat mir ein gutes Angebot gemacht, obendrein noch direkt bei der Bank Aufschub erwirkt und alles für mich erledigt. Ich konnte das Haus dann sogar noch eine Zeit mieten, bis ich was anderes gefunden hatte!',
            name: 'Heinz W.',
            rating: 5,
            date: '15.05.2020',
          },
          {
            image: data.person_2.childImageSharp,
            text:
              'Meine Schwester und ich hatten das Haus gemeinsam mit 2 weiteren Objekten geerbt. Sofort nach Bekanntwerden des Todes unseres Vaters wurden wir mit Verkaufsanfragen überschüttet. Der Partner von WirkaufendeinHaus.at hat uns als einziger gefragt, was wir wirklich wollen! Und wir wollten einfach den Verkauf schnellstmöglich abwickeln! Bereits am nächsten Tag hatten wir schon ein Angebot vorliegen, das wir sofort angenommen haben, weil uns in der Situation die ewigen Verhandlungen mit den Interessenten zu mühsam waren. Und für das 3. Objekt hatte der Partner sogar schon einen vorgemerkten Käufer, der noch mehr zahlte! Alles war schnell und unkompliziert, wie wir es wollten.',
            name: 'Christine S.',
            rating: 5,
            date: '15.05.2020',
          },
          {
            image: data.person_3.childImageSharp,
            text:
              'Ich hatte ein Messiehaus zu verwalten, das aus einer überschuldeten Verlassenschaft stammte. Es taten sich zahlreiche Probleme auf. Es gab keine Baugenehmigung und vieles mehr. An einen Verkauf war so nicht zu denken! Über einen Bekannten wurde ich auf WirkaufendeinHaus.at aufmerksam. Ein zuständiger Experte hat mir gleich ein Angebot ausgehandelt, das mir den risikofreien Verkauf ermöglichte. Direkt aus der Verlassenschaft, die niemand antreten wollte. WirkaufendeinHaus.at hat das Objekt dann saniert, genehmigt und ein Käufer wurde im Handumdrehen gefunden.',
            name: 'Dr. Martin G.',
            position: 'Notar',
            rating: 5,
            date: '15.05.2020',
          },
          {
            image: data.person_4.childImageSharp,
            text:
              'Schon seit langem wollten wir uns von unserem ‚Bastlerhit‘ mit schöner Aussicht trennen. Der Andrang war enorm, jedes Wochenende Besichtigungen! Aber kaufen wollte niemand. Der WirkaufendeinHaus.at Partner hat mich dann mit seiner Bewertung und Erklärung wachgerüttelt und mich darüber informiert, dass Immobilientouristen auch bei viel zu hohen Preisen kommen. Ich habe den Preis dann angenommen, obwohl er unter meinen Erwartungen war, aber Burgenland ist eben nicht Wienerwald! Man muss realistisch bleiben. Damit hat mir WirkaufendeinHaus.at viele unnötige Fahrten und weitere Wochenenden erspart. Ich musste nur noch einmal zum Notar, um alles andere hat sich der Partner gekümmert.',
            name: 'Gabriele A.',
            rating: 5,
            date: '15.05.2020',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-immoankauf" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_1: file(name: { eq: "person_1" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_2: file(name: { eq: "person_2" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_3: file(name: { eq: "person_3" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_4: file(name: { eq: "person_4" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
